import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import OneColTextSection from "../components/OneColTextSection"
import PageSliceZone from "../components/PageSliceZone"
import { useLocation } from "@reach/router"
import OneColSection from "../components/OneColSection"

export default function Page(props) {
  // handle empty data to avoid problems with fetching data from undevined data
  const pageInstance = props.data.prismic.allPages.edges[0] || []
  const pageData = pageInstance.node ? pageInstance.node : {}

  let seoTitle = ""

  // Set default values if null
  if (pageData.seo_title) {
    seoTitle = pageData.seo_title
  } else if (pageData.page_title) {
    seoTitle = "Pachamama Festival" + " | " + pageData.page_title[0].text // eslint-disable-line prefer-template
  } else {
    seoTitle = "Pachamama Festival"
  }

  const seoDescription = pageData.seo_description

  const { pathname } = useLocation()

  return (
    <Layout seoTitle={seoTitle} seoDescription={seoDescription}>
      <PageTitle node={pageData} />
      {pageData.page_sub_title ? (
        <OneColTextSection content={pageData.page_sub_title} />
      ) : null}
      {/* Map */}
      {/* {pathname === "/anreise" ? <OneColSection contentType="address" pathname={pathname}/> : null} */}
      {pageData.content ? (
        <OneColTextSection content={pageData.content} />
      ) : null}
      <PageSliceZone node={pageData} />
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($id: String) {
    prismic {
      allPages(id: $id) {
        edges {
          node {
            seo_description
            seo_title
            body {
              ... on PRISMIC_PageBodyBild_und_text {
                type
                fields {
                  arrangement
                  image
                  text
                }
                primary {
                  section_title
                }
              }
              ... on PRISMIC_PageBodyCall_to_action_grid {
                type
                fields {
                  button_destination {
                    ... on PRISMIC__ExternalLink {
                      target
                      url
                    }
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                      }
                    }
                  }
                  button_label
                  call_to_action_title
                  content
                  featured_image
                }
                primary {
                  section_title
                }
              }
              ... on PRISMIC_PageBodyButton {
                type
                primary {
                  label
                  link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyAufgabenverfolgung {
                type
                fields {
                  date_created
                  date_finished
                  description
                  kommentare
                  priority
                  responsibility
                  task
                  type
                  valued_effort
                  charged_expense
                }
              }
              ... on PRISMIC_PageBodyHtml {
                type
                primary {
                  html_code
                }
              }
              ... on PRISMIC_PageBodyVideo {
                type
                fields {
                  url
                }
                primary {
                  title
                }
              }
              ... on PRISMIC_PageBodyBanner {
                type
                label
                fields {
                  picture
                  position
                }
              }
              ... on PRISMIC_PageBodyText_block {
                type
                primary {
                  text
                  textAlignCenter
                }
              }
              ... on PRISMIC_PageBodyNews {
                primary {
                  archiving_date
                  date_issued
                  image
                  teaser
                  text
                  download_label
                  download_link {
                    _linkType
                    ... on PRISMIC__FileLink {
                      _linkType
                      url
                      name
                    }
                  }
                }
                type
              }
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  end_date
                  event_place
                  event_type
                  image
                  text
                }
              }
              ... on PRISMIC_PageBodyTextspalten {
                type
                fields {
                  text_in_column
                }
              }
              ... on PRISMIC_PageBodyBildergalerie {
                type
                primary {
                  section_title
                  gallerie_type
                  galerie_darstellung
                  url_external_gallerie {
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                  }
                }
                fields {
                  alt
                  image
                  link {
                    ... on PRISMIC__ImageLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      target
                      url
                    }
                  }
                }
              }
            }
            page_title
            page_sub_title
            content
            _meta {
              uid
              id
            }
          }
        }
      }
    }
  }
`
