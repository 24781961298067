import React from 'react'
import OneColSection from '../OneColSection';
import { date } from '../dateFn'

function NewsSlice({ newsData }) {
    // 

    let downloadLink = null

    if (newsData.primary.download_link) {
        downloadLink = (
            <a href={newsData.primary.download_link.url} download={newsData.primary.download_link.name}>
                {newsData.primary.download_label}
            </a>
        )
    }

    return (
        <>
            <OneColSection contentType="component" content={date(newsData.primary.date_issued)} newsDate/>
            <OneColSection contentType="image" content={newsData.primary.image} />
            <OneColSection content={newsData.primary.text} />
            <OneColSection contentType="component" content={downloadLink} />
        </>
    )
}

export default NewsSlice
