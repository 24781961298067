import React from 'react'
import StyledButton from '../common/StyledButton'
import OneColSection from '../OneColSection'

function Button(data) {

    // 

    // Avoiding errors when data is null
    const buttonData = data.buttonData.primary || {}
    const buttonLink = buttonData.link || {}

    return (
        <OneColSection
            contentType="component"
            content={
                <StyledButton href={buttonLink.url} variant="outline-secondary">
                    {buttonData.label}
                </StyledButton>
            }
        />
    )
}

export default Button