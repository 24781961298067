import React from 'react'
import SectionTitle from '../SectionTitle'
import styled from 'styled-components'


function Video(data) {
  

  const youtubeUrl = "https://www.youtube.com/embed/"

  let videoSrcURL = ""
  // const videoSrcURL = youtubeUrl + data.videos.fields[0].url.embed_url.slice(-11)
  // 
  const title = data.videos.primary.title
  
    
    return (
      <>
        {title ? <SectionTitle title={title} /> : null}

            {data.videos.fields.map((video, i) => {
              
              videoSrcURL = youtubeUrl + video.url.embed_url.slice(-11)
              

              return (
                <StyledConteiner key={i}>
                  <StyledIframe
                    src={videoSrcURL}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowfullscreen />
                </StyledConteiner>
              )
          })}
          </>
    )
}

export default Video

    const StyledConteiner = styled.div`
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
      margin-bottom: 2rem;
    `
    const StyledIframe = styled.iframe`
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: 0;
    `
