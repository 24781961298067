import React from 'react'
import parse from 'html-react-parser'
import OneColSection from '../OneColSection'

function HtmlCode(data) {
    // 
    const htmlCode = data.htmlCode.primary.html_code[0].text

  return (
    <>     
          <OneColSection contentType="component" content={parse(`${htmlCode}`)} />
    </>
  )
}

export default HtmlCode