import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import Carousel from '../Homepage/Carousel'
import StyledModal from '../common/StyledModal'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'
import RichText from "../richText"
import CardColumns from 'react-bootstrap/CardColumns'

/* const StyledCardDeck = StyledCardDeck2;
const StyledCard = StyledCard2; */

function ImageGallery({ images }) {

    
    
    let imageArray = images.fields;
    // 

    const changeIndex = (arrayToUpdate, elementToReposition, newIndex) => {
        if (newIndex > arrayToUpdate.length - 1) {
            // 
            return null;
        } else if (!(arrayToUpdate.includes(elementToReposition))) {
            // 
            return null;
        }

        arrayToUpdate.splice(arrayToUpdate.indexOf(elementToReposition), 1);
        arrayToUpdate.splice(elementToReposition, newIndex);
        // 
        return arrayToUpdate;
    }
    
    // Modal event handling
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (i) => {
        // 
        changeIndex(imageArray, i, 0);
        // 
        setShow(true);}
    // End Modal

    // 

    if (images.primary.galerie_darstellung === "Banner"){
        return (
            <StyledBannerDiv>
                {images.fields.map((image, i) => (
                    <img src={image.image.url} alt={image.image.alt} key={i}/>
                ))}
            </StyledBannerDiv>
        )
    }

    if (images.primary.gallerie_type === "Link nach Bildkarussell"){
        // 
        return (
            <>
            <StyledCardDeck2 className="mt-5">
                    {images.fields.map((image, i) => (
                    <StyledCard2 key={i}>
                            <Card.Link onClick={() => {handleShow(i)}}>
                                <Card.Img src={image.image ? image.image.url : null} />
                            <Card.ImgOverlay>
                            </Card.ImgOverlay>
                        </Card.Link>
                    </StyledCard2>
                ))}
            </StyledCardDeck2>
                <StyledModal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    centered
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Body>
                        <Modal.Header closeButton />
                        <Carousel images={imageArray} ImageGallery="true" />
                    </Modal.Body>
                </StyledModal>
            </>
        )
    } 
    
    if (images.primary.gallerie_type === "Link auf externe Site") {
        if(images.primary.galerie_darstellung === "Durcheinander") {
            return (
                <>
                <RichText render={images.primary.section_title} />
                <CardColumns>
                    {images.fields ? images.fields.map((image, i) => (
                        <StyledCard1 key={i}>
                            <Card.Link href={images.primary.url_external_gallerie.url ?
                                images.primary.url_external_gallerie.url : null} target="_blank">
                                <Card.Img src={image.image.url} />
     */}                        </Card.Link>
                        </StyledCard1>
                    )) : null}
                </CardColumns>
                </>
            )
        } else {return (
            <StyledCardDeck2 className="mt-5">
                {images.fields.map((image, i) => (
                    <StyledCard2 key={i}>
                        <Card.Link href={images.primary.url_external_gallerie.url ?
                            images.primary.url_external_gallerie.url : null} target="_blank">
                            <Card.Img src={image.image ? image.image.url : null} />
                            </Card.Link>
                    </StyledCard2>
                ))}
            </StyledCardDeck2>
        )}
    } else {return null}
}

export default ImageGallery

const StyledBannerDiv = styled.div`
width: 100% !important;
/* position: absolute;
left: 0px;
right: 0px; */

img {
    height: 20% !important;
    width: 50%;
}
`
// Masomry style
const StyledCard1 = styled(Card)`
    margin: 0 0 -1px -16px !important;
    border: none;

    .card-img {
        width: 107%;
    }
`
    const StyledCardDeck2 = styled(CardDeck)`
    margin: 0 0.5rem 1rem 0.5rem;
    /* margin: 0 0.5rem -9.8vw 0.5rem; */
    `
    
    const StyledCard2 = styled(Card)`
    // Extra small devices (portrait phones, less than 576px)
    // No media query for xs since this is the default in Bootstrap
        margin-bottom: 1.8rem !important;
    
        .card-img{
            border-radius: 1rem;
        }
    
        border-radius: 1rem;
    
    // Small devices (landscape phones, 576px and up, col-sm)
    @media (min-width: 576px) {
        
        // determins breakepoint
        min-width: 18rem;
    
    }
    // Medium devices (tablets, 768px and up, col-md)
    @media (min-width: 768px) {
    
    }
    // Large devices (desktops, 992px and up, col-lg)
    @media (min-width: 992px) {
        margin-bottom: 1.6rem !important;

    
        // determins breakepoint
        // three pics in row
        /* min-width: 24rem; */
        // four pics in row
        min-width: 20rem;
        
    }
    // Extra large devices (large desktops, 1200px and up, col-xl)
    @media (min-width: 1200px) {
    
    
    }
    `
