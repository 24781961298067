import React from "react"
import {
  DataGrid,
  deDE,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid"
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import { StyledAccordion } from "../common/StyledAccordion"
import { PrismicRichText } from "@prismicio/react"
import { date } from "../dateFn"
import OneColSection from "../OneColSection"
import StyledLinkButton from "../common/StyledLinkButton"
// import uniqueID from "../../utils/uniqueID"

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

function Aufgabenverfolgung(data) {
  let tasks = data.aufgaben.fields

  console.log('Tesks', tasks)

  // uuid not persistent
  /*   for (let index = 0; index < tasks.length; index++) {
    const element = tasks[index]
    element.uuid = uniqueID()
    console.log("uuid", element.uuid)
  } */

  let heighPrioTasks = tasks.filter(function (e) {
    return e.priority === "hoch" && e.date_finished === null
  })

  heighPrioTasks.sort((a, b) => {
    let da = new Date(a.date_created),
      db = new Date(b.date_created)
    return db - da
  })

  let mediumPrioTasks = tasks.filter(function (e) {
    return e.priority === "mittel" && e.date_finished === null
  })

  mediumPrioTasks.sort((a, b) => {
    let da = new Date(a.date_created),
      db = new Date(b.date_created)
    return db - da
  })

  let hAndMPrioTasks = [...heighPrioTasks, ...mediumPrioTasks]

  const taskList = (
    <>
      <h3>Aktive Aufgaben hoher und mittlerer Priorität</h3>
      <StyledAccordion defaultActiveKey="0">
        {hAndMPrioTasks.map((task, i) => {
          // {data.aufgaben.fields.map((task, i) => {
          const taskAccordion = (
            <Card key={i}>
              <Card.Header>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey={`0 + ${i}`}
                >
                  <PrismicRichText
                    field={task.task}
                    components={{
                      heading2: ({ children }) => <h4>{children}</h4>,
                    }}
                  />
                  <p>
                    Zuständig: {task.responsibility} | Prio: {task.priority} |
                    Datum: {date(task.date_created)} | Art: {task.type}
                  </p>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={`0 + ${i}`}>
                <Card.Body>
                  Beschreibung:
                  <PrismicRichText
                    field={task.description}
                    components={{
                      paragraph: ({ children }) => <p>{children}</p>,
                    }}
                  />
                  <p>Geschätzter Aufwand (Min): {task.valued_effort}</p>
                  {task.attachment ? (
                    <p>
                      <StyledLinkButton small={true} to={`/${task.attachment}`}>
                        Attachement
                      </StyledLinkButton>
                    </p>
                  ) : null}
                  Kommentare:
                  <PrismicRichText
                    field={task.kommentare}
                    components={{
                      paragraph: ({ children }) => (
                        <p className="task">{children}</p>
                      ),
                    }}
                  />
                  <p>Verrechneter Aufwand (Min): {task.charged_expense}</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )
          if (!task.date_finished) {
            return <>{taskAccordion}</>
          } else {
            return null
          }
        })}
      </StyledAccordion>
    </>
  )

  let rows = []

  for (let index = 0; index < tasks.length; index++) {
    const task = tasks[index]
    // each row needs unique id
    task.id = index
    task.title = task.task[0].text
    rows.push(task)
  }

  let columns = [
    { field: "id", headerName: "ID", width: 110 },
    // { field: 'id', hide: true },
    { field: "title", headerName: "Aufgabe", flex: 1.5 },
    { field: "priority", headerName: "Prio", width: 160 },
    { field: "responsibility", headerName: "Zuständig", flex: 0.5 },
    { field: "date_finished", headerName: "Erledigt am", flex: 0.5 },
  ]

  return (
    <>
      <OneColSection contentType="component" content={taskList} />
      <div
        style={{ height: 2000, width: "100%" }}
        className="d-none d-sm-block"
      >
        <h4>Übersicht alle Aufgaben</h4>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          //   onRowClick={(GridRowParams) => { navigate(`../${GridRowParams.id}`) }}
          components={{
            Toolbar: CustomToolbar,
          }}
          sortModel={[
            {
              field: "date_finished",
              sort: "asc",
            },
            {
              field: "priority",
              sort: "asc",
            },
          ]}
        />
      </div>
    </>
  )
}

export default Aufgabenverfolgung
