import React from "react"
import ImageGallery from "./slices/ImageGallery"
import TextInCols from "./slices/TextInCols"
import Event from "./slices/Event"
import NewsSlice from "./slices/NewsSlice"
import OneColSection from "./OneColSection"
import Video from "./slices/Video"
import HtmlCode from "./slices/HtmlCode"
import Aufgabenverfolgung from "./slices/Aufgabenverfolgung"
import Button from "./slices/Button"
import CallToActionGrid from "./Homepage/callToActionGrid"
import ImageAndText from "./ImageAndText"
import Banner from "./slices/Banner"

function PageSliceZone({ node }) {
  //
  if (node) {
    return (
      <>
        {node.body
          ? node.body.map((bodyContent, i) => {
              if (bodyContent.type === "bild_und_text") {
                return <ImageAndText key={i} data={bodyContent} />
              }

              if (bodyContent.type === "call_to_action_grid") {
                return (
                  <CallToActionGrid
                    key={i}
                    title={bodyContent.primary.section_title}
                    callToActions={bodyContent.fields}
                  />
                )
              }

              if (bodyContent.type === "button") {
                return <Button buttonData={bodyContent} key={i} />
              }

              if (bodyContent.type === "aufgabenverfolgung") {
                return <Aufgabenverfolgung aufgaben={bodyContent} key={i} />
              }
              if (bodyContent.type === "html") {
                return <HtmlCode htmlCode={bodyContent} key={i} />
              }
              if (bodyContent.type === "video") {
                return <Video videos={bodyContent} key={i} />
              }
              if (bodyContent.type === "bildergalerie") {
                return <ImageGallery images={bodyContent} key={i} />
              }
              if (bodyContent.type === "text_block") {
                return (
                  <OneColSection
                    textAlign={bodyContent.primary.textAlignCenter}
                    content={bodyContent.primary.text}
                    key={i}
                  />
                )
              }
              if (bodyContent.type === "banner") {
                if (bodyContent.fields.length > 0) {
                  if (bodyContent.fields[0].position !== "Ganz oben") {
                    return (
                      <OneColSection
                        contentType="banner"
                        content={bodyContent.fields[0]}
                        key={i}
                      />
                    )
                  } else {
                    return <Banner content={bodyContent.fields[0]} />
                  }
                } else {
                  return null
                }
              }

              if (bodyContent.type === "events") {
                return <Event eventData={bodyContent} key={i} />
              }
              if (bodyContent.type === "news") {
                return <NewsSlice newsData={bodyContent} key={i} />
              }
              if (bodyContent.type === "textspalten") {
                return <TextInCols contents={bodyContent} key={i} />
              } else {
                return null
              }
            })
          : null}
      </>
    )
  } else {
    return null
  }
}

export default PageSliceZone
