import React from 'react'
import { Row, Col } from 'react-bootstrap'

function TwoColSection({colleft, colright, styles}) {
    
    if(styles === "full_with"){
        return (
            <Row className="mt-5 mx-1 mx-md-3">
                {/* img */}
                <Col sm={12} lg={6}>{colleft}</Col>
                {/* Text */}
                <Col sm={12} lg={6} className="pt-4 pr-md-5" >{colright}</Col>
            </Row>
        )
    } else {
        return (
            <Row className="mt-3">
                <Col lg={2} md={1}></Col>
                <Col sm={12} md={5} lg={4} className="mr-md-4">{colleft}</Col>
                <Col sm={12} md={5} lg={4} className="ml-md-4">{colright}</Col>
                <Col lg={2} md={1}></Col>
            </Row>
        )
    }
}

export default TwoColSection
