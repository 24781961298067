import React from 'react'
import TwoColSection from '../TwoColSection'
import { Row, Col } from 'react-bootstrap'
import ListGroup from 'react-bootstrap/ListGroup'
import RichText from "../richText"
import styled from 'styled-components'
import { date, day, time } from '../dateFn'

function Event({ eventData }) {

    if (eventData.primary.end_date) {
        eventData.primary.endDay = (" - " + day(eventData.primary.end_date));
    }
    const imgData = eventData.primary.image
    const text = <RichText render={eventData.primary.text} />
    // const image = <StyledImage src={eventData.primary.image.url} />
    const image = imgData ? <StyledImage
                    src={imgData.url}
                    srcSet={`${imgData.url} ${imgData.dimensions.width}w,
                    ${imgData.tablet.url} ${imgData.tablet.dimensions.width}w,
                    ${imgData.laptop.url} ${imgData.laptop.dimensions.width}w`}
                    alt={imgData.alt} 
                /> : null

    return (
        <>
            <Row className="mb-5 mx-auto">
                <Col md={1} className="d-none d-lg-block"></Col>
                {/* horizontal tablet and + */}
                <Col className="mr-md-4 d-none d-sm-block text-center">
                    <ListGroup className='text-center' style={{ background: "transparent", border: "0 !important" }} horizontal>
                        <StyledListGroupItem >{eventData.primary.event_type}</StyledListGroupItem>
                        <StyledListGroupItem >{eventData.primary.event_place}</StyledListGroupItem>
                        <StyledListGroupItem >{date(eventData.primary.date_time)}</StyledListGroupItem>
                        <StyledListGroupItem >{day(eventData.primary.date_time)}{eventData.primary.endDay}</StyledListGroupItem>
                        <StyledListGroupItem >{time(eventData.primary.date_time)} - {time(eventData.primary.end_time)}</StyledListGroupItem>
                    </ListGroup>
                </Col>
                {/* vergical on mobile view */}
                <Col className="mr-md-4 d-block d-sm-none">
                    <ListGroup >
                        <StyledListGroupItemMobile >{eventData.primary.event_type}</StyledListGroupItemMobile>
                        <StyledListGroupItemMobile >{eventData.primary.event_place}</StyledListGroupItemMobile>
                        <StyledListGroupItemMobile >{date(eventData.primary.date_time)}</StyledListGroupItemMobile>
                        <StyledListGroupItemMobile >{day(eventData.primary.date_time)}{eventData.primary.endDay}</StyledListGroupItemMobile>
                        <StyledListGroupItemMobile >{time(eventData.primary.date_time)} - {time(eventData.primary.end_time)}</StyledListGroupItemMobile>
                    </ListGroup>
                </Col>
                <Col md={1} className="d-none d-lg-block"></Col>
            </Row>
            <TwoColSection colleft={image} colright={text} styles={"full_with"} />
        </>
    )
}

export default Event

    const StyledImage = styled.img`
    // Extra small devices (portrait phones, less than 576px)
    // No media query for xs since this is the default in Bootstrap
    width: 100%;
    border-radius: 4rem;
    margin-bottom: 2rem;

    // Small devices (landscape phones, 576px and up, col-sm)
    @media (min-width: 576px) {

    }
    // Medium devices (tablets, 768px and up, col-md)
    @media (min-width: 768px) {
    
    }
    // Large devices (desktops, 992px and up, col-lg)
    @media (min-width: 992px) {

        border-radius: 8rem;
        padding: 2rem;
    }
    // Extra large devices (large desktops, 1200px and up, col-xl)
    @media (min-width: 1200px) {

    }
    `
    
    const StyledListGroupItem = styled(ListGroup.Item)`
        background: transparent;
        color: var(--page-text-color);
        border-color: var(--page-text-color);
        border-top: none;
        border-bottom: none;
        border-left: none;
        font-size: 1.2rem;
    
        &:nth-child(5) {
            border-right: none;
        }
    `
    const StyledListGroupItemMobile = styled(ListGroup.Item)`
        background: transparent;
        margin-top: 1rem;
        border-top: none;
        border-right: none;
        border-left: none;
        font-size: large;
        color: var(--page-text-color);
    `