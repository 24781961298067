import React from 'react'
import styled from 'styled-components'

function Banner({content}) {

    const imgData = content.picture || {}

    // 

  return (

      <StyledBannerContainer>
          {imgData.tablet || imgData.laptop ?
          <StyledBannerImg 
            src={imgData.url}
            srcSet={`
            ${imgData.url} ${imgData.dimensions.width}w,
            ${imgData.tablet.url} ${imgData.tablet.dimensions.width}w,
            ${imgData.laptop.url} ${imgData.laptop.dimensions.width}w
            `}
            alt={imgData.alt}
              /> : null}
      </StyledBannerContainer>
  )
}

export default Banner

const StyledBannerContainer = styled.div`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

position: absolute;
top: 0;
left: 0;
z-index: -1;
margin-top: 60px;
width: 100%;

/* background-image: url(${ props => props.imgUrl}); */
// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {
    margin-top: 65px;
    
}
// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {
    margin-top: 86px;
    
}
// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {
    margin-top: 0;

}
// Extra large devices (large desktops, 1200px and up, col-xl)
@media (min-width: 1200px) {

}
`

const StyledBannerImg = styled.img`
    width: 100%;
    height: auto;
`