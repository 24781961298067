import styled from 'styled-components'
import { Image } from 'react-bootstrap'

const StyledImage = styled(Image)`
    // Extra small devices (Team phones, less than 576px)
  // No media query for xs since this is the default in Bootstrap
    border-radius: 30px;
    width: 100%;
    height: auto;
  
  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
  
  }
  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
  
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
    border-radius: 100px;
  
  }
  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {
  
  }
  `
  export default StyledImage