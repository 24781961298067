import React from "react"
import { Row, Col } from "react-bootstrap"
import styled from "styled-components"
import useMediaQuery from "@mui/material/useMediaQuery"
import SectionTitle from "./SectionTitle"
import RichText from "./richText"
import StyledImage from "./common/StyledImage"

export default function ImageAndText({ image, text, counter, data }) {
  const matches = useMediaQuery("(min-width:768px)")

  if (data) {
    if (matches) {
      return (
        <>
          {data.primary.section_title ? (
            <SectionTitle title={data.primary.section_title} />
          ) : null}
          {data.fields.map((item, i) => {
            //
            if (item.arrangement === "Bild links Text rechts") {
              return (
                <StyledRow
                  className="pt-md-1 pb-md15 mt-md-1 mb-1 mb-md-1 align-items-center justify-content-md-center"
                  key={i}
                >
                  <StyledTextCol sm={12} md={6} className="pt-4 pb-4">
                    <RichText render={item.text} />
                  </StyledTextCol>
                  <StyledImageCol sm={12} md={4}>
                    {item.image ? <StyledImage src={item.image.url} /> : null}
                  </StyledImageCol>
                </StyledRow>
              )
            } else {
              return (
                <StyledRow
                  className="pt-md-1 pb-md15 mt-md-1 mb-1 mb-md-1 align-items-center justify-content-md-center"
                  key={i}
                >
                  <StyledImageCol sm={12} md={4}>
                    {item.image ? <StyledImage src={item.image.url} /> : null}
                  </StyledImageCol>
                  <StyledTextCol sm={12} md={6} className="pt-4 pb-4">
                    <RichText render={item.text} />
                  </StyledTextCol>
                </StyledRow>
              )
            }
          })}
        </>
      )
    } else {
      return (
        <>
          {data.primary.section_title ? (
            <SectionTitle title={data.primary.section_title} />
          ) : null}
          {data.fields.map((item, i) => {
            return (
              <StyledRow
                className="pt-md-1 pb-md15 mt-md-1 mb-1 mb-mb-1"
                key={i}
              >
                <Col lg={2} md={1}></Col>
                <StyledImageCol sm={12} md={5} lg={4}>
                  {item.image ? <StyledImage src={item.image.url} /> : null}
                </StyledImageCol>
                <StyledTextCol sm={12} md={5} lg={4} className="pt-4 pb-4">
                  <RichText render={item.text} />
                </StyledTextCol>
                <Col lg={2} md={1}></Col>
              </StyledRow>
            )
          })}
        </>
      )
    }
  }

  if (matches) {
    // Text left image right
    if (counter % 2 === 0) {
      return (
        <StyledRow className="pt-md-1 pb-md15 mt-md-1 mb-1 mb-md-1 align-items-center justify-content-md-center">
          <StyledTextCol sm={12} md={6} className="pt-4 pb-4">
            <div>{text}</div>
          </StyledTextCol>
          <StyledImageCol sm={12} md={4}>
            {image}
          </StyledImageCol>
        </StyledRow>
      )
      // Image left text right
    } else {
      return (
        <StyledRow className="pt-md-1 pb-md15 mt-md-1 mb-1 mb-md-1 align-items-center justify-content-md-center">
          <StyledImageCol sm={12} md={4}>
            {image}
          </StyledImageCol>
          <StyledTextCol sm={12} md={6} className="pt-4 pb-4">
            <div>{text}</div>
          </StyledTextCol>
        </StyledRow>
      )
    }
    // Mobile view: image top, text below
  } else {
    return (
      <StyledRow className="pt-md-1 pb-md15 mt-md-1 mb-1 mb-mb-1">
        <Col lg={2} md={1}></Col>
        <StyledImageCol sm={12} md={5} lg={4}>
          {image}
        </StyledImageCol>
        <StyledTextCol sm={12} md={5} lg={4} className="pt-4 pb-4">
          <div>{text}</div>
        </StyledTextCol>
        <Col lg={2} md={1}></Col>
      </StyledRow>
    )
  }
}

const StyledRow = styled(Row)`
  // Extra small devices (portrait phones, less than 576px)
  // No media query for xs since this is the default in Bootstrap
  margin-top: 4rem !important;
  background: var(--portrait-img-bg);

  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
  }
  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
    margin-top: 0 !important;
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
    margin-top: 2rem !important;
  }
  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {
  }
`

const StyledTextCol = styled(Col)`
  p {
    color: var(--portrait-img-text-color);
  }
  h4 {
    color: var(--portrait-img-title-color);
  }
  color: var(--page-bg-color);

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    display: block;
    align-items: center;
    justify-content: center;
  }
`

const StyledImageCol = styled(Col)`
  margin-bottom: 2rem;
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    flex: 1 0 50%;
  }
`
